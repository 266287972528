import "core-js/modules/es.array.push.js";
import { Card, Toast, Button, Stepper, Icon } from 'vant';
import PaymentChannelPopup from '../common/PaymentChannelPopup.vue';
// import BackOff from "../common/BackOff.vue";

import { bill, updateCount } from '../../api/shoppingcart.js';
// import {cartSubmit} from "../../api/order.js";
import BackOff from '../common/BackOff.vue';
export default {
  components: {
    BackOff,
    PaymentChannelPopup,
    [Card.name]: Card,
    [Button.name]: Button,
    [Stepper.name]: Stepper,
    [Icon.name]: Icon
  },
  data() {
    return {
      productType: Number,
      subCount: Number,
      cartItemIds: this.$route.query.cartItemIds,
      addressId: this.$route.query.userAddressId,
      skuId: this.$route.query.skuId,
      backUrl: this.$route.query.back_url + '&back=1',
      productId: this.$route.query.productId,
      orderId: '',
      // addressId: Number,
      paymentShow: false,
      packages: [],
      addressInfo: {},
      paymentOptions: [],
      postFee: 0,
      originalPostageFee: 0,
      totalFee: 0,
      realFee: 0,
      originalTotalFee: 0,
      discountFee: 0,
      currencyCode: '',
      // 提交按钮状态
      submitBtnLoading: false,
      submitBtnDisabled: false,
      countDisabled: false,
      canSelectAddress: true
    };
  },
  watch: {
    // orderId(val, valOld) {
    orderId(val) {
      if (val !== '') {
        this.countDisabled = true;
        this.canSelectAddress = false;
      }
    }
  },
  created: async function () {
    this.getBill();
  },
  methods: {
    goAddressList() {
      this.$router.push({
        path: '/user/address/list',
        query: {
          backUrl: this.$route.fullPath,
          address: 1
        }
      });
    },
    getBill() {
      const selectedAddressId = localStorage.getItem('selectedAddressId');
      if (selectedAddressId) {
        this.addressId = selectedAddressId;
      }
      const billData = {
        cartItemIds: this.cartItemIds,
        userAddressId: this.addressId
      };
      bill(billData).then(result => {
        this.packages = result.data.data.packages;
        this.addressInfo = result.data.data.addressInfo;
        this.paymentOptions = result.data.data.paymentOptions;
        this.postFee = (result.data.data.postFee / 100).toFixed(2);
        this.originalPostageFee = result.data.data.originalPostageFee;
        this.totalFee = result.data.data.totalFee;
        this.realFee = result.data.data.realFee;
        this.productAmount = result.data.data.productAmount;
        this.discountFee = result.data.data.discountFee;
        this.discountFee = result.data.data.discountFee;
        this.currencyCode = result.data.data.currencyCode;
        this.productType = result.data.data.packages[0].product.type;
        if (this.productType == 4) {
          this.subCount = result.data.data.packages[0].subCount;
        }
        this.getListDone();
      }).catch(error => {
        error;
        this.$router.push({
          path: '/order/list'
        });
      });
    },
    onAddressSelect() {
      this.$router.push({
        path: '/user/address/list',
        query: {
          backUrl: this.$route.fullPath
        }
      });
    },
    onSubmit() {
      this.paymentShow = true;
    },
    callbackForClose() {
      if (this.paymentShow) {
        this.paymentShow = false;
      }
    },
    onCountChange(cartItem, ope) {
      let count = cartItem.count;
      if (ope === 'plus') {
        count++;
      } else {
        count--;
      }
      if (count <= 0) {
        Toast('invalid num');
        return;
      }
      this.whileGetList();
      const countData = {
        cartItemId: cartItem.cartId,
        count: count
      };
      updateCount(countData).then(res => {
        res.data;
        this.getBill();
      });
    },
    whileGetList() {
      this.submitBtnDisabled = true;
      this.countDisabled = true;
      this.submitBtnLoading = true;
    },
    getListDone() {
      this.submitBtnDisabled = false;
      this.submitBtnLoading = false;
      this.countDisabled = false;
    }
  }
};