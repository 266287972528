import request from '../util/request'

export function paymentAvailableList() {
  return request({
    url: '/common/payment/available/list',
    method: 'get'
  })
}

export function commonHome() {
  return request({
    url: '/common/home',
    method: 'get'
  })
}

export function areaAvailableCountries() {
  return request({
    url: '/common/area/available/countries',
    method: 'get'
  })
}

// 获取国家详细地址
export function areaAvailableCountriesDetail(countryName) {
  return request({
    url: '/common/area/available/countries/detail-v2?countryName=' + countryName,
    method: 'get'
  })
}

// 获取购买之后有几个流程
export function orderProcessList() {
  return request({
    url: '/common/order/process/list',
    method: 'get'
  })
}
