var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('van-popup', {
    staticStyle: {
      "position": "fixed",
      "bottom": "0"
    },
    attrs: {
      ":before-close": _vm.closePopup(),
      "position": "bottom"
    },
    model: {
      value: _vm.canPopup,
      callback: function ($$v) {
        _vm.canPopup = $$v;
      },
      expression: "canPopup"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "100vw",
      "height": "12.82vw",
      "border": "solid 1px #BBBBBB",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "font-size": "20px",
      "font-weight": "bold"
    }
  }, [_vm._v(" Select Payment Channel")]), _c('ul', [_c('van-radio-group', {
    model: {
      value: _vm.select,
      callback: function ($$v) {
        _vm.select = $$v;
      },
      expression: "select"
    }
  }, _vm._l(_vm.paymentChannelList, function (channel, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function ($event) {
          return _vm.onChannelClick(channel.id);
        }
      }
    }, [_c('div', {
      staticStyle: {
        "display": "flex",
        "margin": "1vw",
        "height": "12vw"
      }
    }, [_c('div', {
      staticStyle: {
        "width": "12vw",
        "margin-left": "4vw"
      }
    }, [_c('van-image', {
      attrs: {
        "src": channel.icon
      }
    })], 1), _c('div', {
      staticStyle: {
        "margin-left": "4vw",
        "width": "60%",
        "margin": "1vw"
      }
    }, [_c('div', [_vm._v(" " + _vm._s(channel.name) + " ")]), _c('div', {
      staticStyle: {
        "color": "#999"
      }
    }, [_vm._v(" " + _vm._s(channel.desc) + " ")])]), _c('div', {
      staticStyle: {
        "width": "15%",
        "padding-top": "4vw"
      }
    }, [_c('van-radio', {
      attrs: {
        "name": channel.id
      }
    })], 1)])]);
  }), 0)], 1), _c('br'), _c('br'), _c('br'), _c('div', {
    staticStyle: {
      "border": "solid 0.5px lightgray",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "height": "12.82vw",
      "width": "100vw",
      "position": "fixed",
      "bottom": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "width": "67.69vw"
    }
  }, [_c('span', {
    staticStyle: {
      "margin-left": "25vw",
      "font-size": "14px",
      "height": "12.82vw",
      "width": "19vw",
      "align-items": "center",
      "display": "flex",
      "justify-content": "center"
    }
  }, [_vm._v(" Total Fee: ")]), _c('span', {
    staticStyle: {
      "color": "#BD3124",
      "font-size": "14px",
      "height": "12.82vw",
      "width": "6vw",
      "margin-left": "2vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.currencyCode) + " ")]), _c('span', {
    staticStyle: {
      "display": "flex",
      "height": "12.82vw",
      "width": "20vw",
      "font-weight": "bold",
      "font-size": "20px",
      "color": "#BD3124",
      "margin-right": "1vw",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v(" " + _vm._s((_vm.totalFee / 100).toFixed(2)) + " ")])]), _c('van-button', {
    staticStyle: {
      "height": "12.82vw",
      "width": "32.31vw",
      "font-size": "20px",
      "color": "#E8E4E4",
      "font-weight": "bold",
      "border": "solid 0px #D56460",
      "background-color": "#D56460",
      "margin-right": "0vw"
    },
    attrs: {
      "type": "primary",
      "size": "large"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.onPayLaunch();
      }
    }
  }, [_vm._v("      Submit ")])], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };