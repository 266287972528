import "core-js/modules/es.array.push.js";
import { paymentAvailableList } from '../../api/common.js';
import { orderLaunch } from '../../api/payment.js';
import { cartSubmit } from '../../api/order.js';
import { Popup, Image as VanImage, Button, Radio, RadioGroup
// Cell,
// CellGroup
} from 'vant';
export default {
  name: 'PaymentChannelPopup',
  components: {
    [Popup.name]: Popup,
    [VanImage.name]: VanImage,
    [Button.name]: Button,
    [Radio.name]: Radio,
    [RadioGroup.name]: RadioGroup
    // [Cell.name]: Cell,
    // [CellGroup.name]: CellGroup
  },

  props: {
    // eslint-disable-next-line vue/require-prop-types
    productId: {
      typeof: Number,
      default: ''
    },
    // eslint-disable-next-line vue/require-prop-types
    skuId: {
      typeof: Number,
      default: ''
    },
    // eslint-disable-next-line vue/require-prop-types
    addressId: {
      typeof: Number,
      default: ''
    },
    // eslint-disable-next-line vue/require-prop-types
    currencyCode: {
      typeof: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-prop-types
    totalFee: {
      typeof: Number,
      default: ''
    },
    // eslint-disable-next-line vue/require-prop-types
    cartItemIds: {
      typeof: Array,
      default: () => []
    },
    // eslint-disable-next-line vue/require-prop-types
    orderId: {
      typeof: String,
      default: ''
    },
    // eslint-disable-next-line vue/require-prop-types
    popup: {
      typeof: Boolean,
      default: false
    }
  },
  data() {
    return {
      paymentChannelList: [],
      select: 1,
      canPopup: this.popup
    };
  },
  watch: {
    // popup(val, valOld){  提示valOld定义但是没有用到,先删除
    popup(val) {
      this.canPopup = val;
    }
  },
  created: async function () {
    this.getTraceOrderList();
  },
  methods: {
    gtag() {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(arguments);
    },
    getTraceOrderList() {
      paymentAvailableList().then(res => {
        this.paymentChannelList = res.data.data;
      });
    },
    onPayLaunch() {
      // eslint-disable-next-line eqeqeq
      if (process.env.NODE_ENV == 'production') {
        this.gtag('event', 'pay' + this.productId);
      }
      if (!this.orderId) {
        localStorage.removeItem('selectedAddressId');
        const submitData = {
          'cartItemIds': this.cartItemIds,
          'paymentChannel': this.select,
          'addressId': this.addressId,
          'remark': 'remark'
        };
        cartSubmit(submitData).then(res => {
          // 清除图片位置的本地缓存
          localStorage.removeItem('localPictureInfoStorage' + this.skuId);
          // 清除地址的本地缓存
          localStorage.removeItem('selectedAddressId');
          const orderId = res.data.data.id;
          // 发起支付
          const orderData = {
            'orderId': orderId,
            'paymentChannel': this.select
          };
          orderLaunch(orderData).then(res => {
            const payUrl = res.data.data.payment_data.pay_url;
            // const payUrlRedirect = payUrl + '&payDoneRedirect=' + window.location.origin + '/#/order/pay/result?orderId=' + orderId
            // console.log('payUrlRedirect = ' + payUrlRedirect)
            window.open(payUrl, '_self');
            // console.log('已跳转支付')
          });
        }).catch(error => {
          error;
          this.$router.push({
            path: '/order/list'
          });
        });
      } else {
        // console.log('=========else=====')
        const orderData = {
          'orderId': this.orderId,
          'paymentChannel': this.select
        };
        orderLaunch(orderData).then(res => {
          // console.log('res = ' + res.data.data.payment_data.pay_url)
          const payUrl = res.data.data.payment_data.pay_url;
          // const payUrlRedirect = payUrl + '&payDoneRedirect=' + window.location.origin + '/#/order/pay/result?orderId=' + this.orderId
          // console.log('payUrlRedirect = ' + payUrlRedirect)
          window.open(payUrl, '_self');
          console.log('已跳转支付');
        });
      }
    },
    onChannelClick(id) {
      this.select = id;
    },
    closePopup() {
      if (this.canPopup) {
        return false;
      }
      // console.log('closePopup')
      this.$emit('callbackForClose');
    }
  }
};