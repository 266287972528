import request from '../util/request'

export function submitCart(data) {
  return request({
    url: '/shoppingcart/submit',
    method: 'post',
    data: data
  })
}

export function bill(params) {
  return request({
    url: '/shoppingcart/bill',
    method: 'get',
    params: params
  })
}

export function updateCount(data) {
  return request({
    url: '/shoppingcart/update/count',
    method: 'put',
    data: data
  })
}
