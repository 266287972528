var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticStyle: {
      "background-color": "#E8E4E4"
    }
  }, [_c('back-off', {
    attrs: {
      "url": _vm.backUrl,
      "title": "Bill"
    }
  }), _c('div', [_vm.addressInfo.consignee !== undefined ? _c('div', {
    staticStyle: {
      "border-radius": "10px",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "height": "32.05vw",
      "background-color": "#FFFFFF"
    },
    on: {
      "click": function ($event) {
        return _vm.goAddressList();
      }
    }
  }, [_c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "10.68vw",
      "margin-left": "4vw",
      "margin-top": "2vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.consignee) + "   " + _vm._s(_vm.addressInfo.phone) + " ")]), _c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "6.68vw",
      "margin-left": "4vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.addressLine2) + "   " + _vm._s(_vm.addressInfo.addressLine1) + " "), _c('span', {
    staticStyle: {
      "color": "#CECECE",
      "font-size": "6vw",
      "width": "6.87vw",
      "height": "6.68vw",
      "position": "absolute",
      "right": "4vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(">")])]), _c('div', {
    staticStyle: {
      "width": "90.87vw",
      "height": "10.68vw",
      "margin-left": "4vw",
      "margin-bottom": "2vw",
      "align-items": "center",
      "display": "flex"
    }
  }, [_vm._v(" " + _vm._s(_vm.addressInfo.city) + "   " + _vm._s(_vm.addressInfo.state) + "   " + _vm._s(_vm.addressInfo.postalCode) + " ")])]) : _vm._e(), _vm.packages.length > 0 ? _c('ul', {
    staticStyle: {
      "border-radius": "10px",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "background-color": "#FFFFFF"
    }
  }, _vm._l(_vm.packages, function (item) {
    return _c('li', {
      key: item.id,
      staticStyle: {
        "margin-bottom": "0rem"
      }
    }, [_c('van-card', {
      staticClass: "cartItem",
      attrs: {
        "tag": "",
        "price": (item.sku.salePrice / 100).toFixed(2),
        "desc": item.sku.name,
        "currency": item.product.currencyCode,
        "title": item.product.title,
        "thumb": item.sku.coverImg != '' ? item.sku.coverImg : item.product.coverImg,
        "origin-price": (item.sku.originalPrice / 100).toFixed(2)
      },
      scopedSlots: _vm._u([_vm.productType != 4 ? {
        key: "num",
        fn: function () {
          return [_c('van-stepper', {
            staticStyle: {
              "display": "flex"
            },
            attrs: {
              "theme": "round",
              "max": "10",
              "integer": "",
              "disabled": _vm.countDisabled,
              "disable-input": ""
            },
            on: {
              "plus": function ($event) {
                return _vm.onCountChange(item, 'plus');
              },
              "minus": function ($event) {
                return _vm.onCountChange(item, 'minus');
              }
            },
            model: {
              value: item.count,
              callback: function ($$v) {
                _vm.$set(item, "count", $$v);
              },
              expression: "item.count"
            }
          })];
        },
        proxy: true
      } : null], null, true)
    }), _vm.productType == 4 ? _c('span', {
      staticStyle: {
        "position": "relative",
        "display": "flex",
        "left": "87vw",
        "bottom": "8.5vw",
        "width": "3vw",
        "height": "3vw"
      }
    }, [_vm._v("x" + _vm._s(_vm.subCount))]) : _vm._e()], 1);
  }), 0) : _vm._e(), _vm.packages.length > 0 ? _c('div', {
    staticStyle: {
      "font-size": "3.59vw",
      "color": "#606060",
      "border-radius": "10px",
      "overflow": "hidden",
      "margin-left": "2.56vw",
      "margin-top": "2vw",
      "width": "94.87vw",
      "height": "32.31vw",
      "background-color": "#FFFFFF"
    }
  }, [_c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "44.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v(" Shipping Fee")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30vw",
      "margin-right": "10vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-end"
    }
  }, [_vm._v(_vm._s(_vm.currencyCode) + " " + _vm._s(_vm.postFee))])]), _c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "44.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v("Sub Total")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30vw",
      "margin-right": "10vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-end"
    }
  }, [_vm._v(_vm._s(_vm.currencyCode) + " " + _vm._s((_vm.realFee / 100).toFixed(2)))])]), _c('div', {
    staticStyle: {
      "height": "10.58vw",
      "width": "94.87vw",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center"
    }
  }, [_c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "44.87vw",
      "display": "flex",
      "margin-left": "4vw",
      "align-items": "center",
      "justify-content": "flex-start"
    }
  }, [_vm._v("Amount To Pay")]), _c('span', {
    staticStyle: {
      "height": "10.58vw",
      "width": "30vw",
      "margin-right": "10vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "flex-end"
    }
  }, [_vm._v(_vm._s(_vm.currencyCode) + " " + _vm._s((_vm.realFee / 100).toFixed(2)))])])]) : _vm._e(), _c('payment-channel-popup', {
    attrs: {
      "product-id": _vm.productId,
      "sku-id": _vm.skuId,
      "order-id": _vm.orderId,
      "cart-item-ids": _vm.cartItemIds,
      "address-id": _vm.addressId,
      "popup": _vm.paymentShow,
      "currency-code": _vm.currencyCode,
      "total-fee": _vm.realFee
    },
    on: {
      "callbackForClose": _vm.callbackForClose
    }
  }), _c('div', {
    staticStyle: {
      "border": "solid 1px gray",
      "display": "flex",
      "justify-content": "space-between",
      "align-items": "center",
      "height": "12.82vw",
      "width": "100vw",
      "position": "fixed",
      "bottom": "0"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "width": "60vw"
    }
  }, [_c('span', {
    staticStyle: {
      "margin-left": "10vw",
      "font-size": "3.59vw",
      "height": "12.82vw",
      "width": "19vw",
      "align-items": "center",
      "display": "flex",
      "justify-content": "center"
    }
  }, [_vm._v(" Total Fee: ")]), _c('span', {
    staticStyle: {
      "color": "#BD3124",
      "font-size": "3.59vw",
      "height": "12.82vw",
      "width": "6vw",
      "margin-left": "2vw",
      "display": "flex",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.currencyCode) + " ")]), _c('span', {
    staticStyle: {
      "display": "flex",
      "height": "12.82vw",
      "width": "20vw",
      "font-weight": "bold",
      "font-size": "5.13vw",
      "color": "#BD3124",
      "margin-right": "1vw",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_vm._v(" " + _vm._s((_vm.realFee / 100).toFixed(2)) + " ")])]), _c('van-button', {
    staticStyle: {
      "display": "flex",
      "align-items": "center",
      "justify-content": "center",
      "height": "12.82vw",
      "width": "40vw",
      "padding-right": "4vw",
      "font-size": "5.13vw",
      "color": "#E8E4E4",
      "font-weight": "bold",
      "border": "solid 0px #D56460",
      "background-color": "#D56460",
      "margin-right": "0vw"
    },
    attrs: {
      "type": "primary",
      "size": "large"
    },
    nativeOn: {
      "click": function ($event) {
        return _vm.onSubmit();
      }
    }
  }, [_vm._v("      Place Order ")])], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };